import { Controller } from "@hotwired/stimulus"

// Provides tabbed navigation functionality with sticky tabs and auto scrolling
//
// Connects to data-controller="tabber"

export default class extends Controller {
  connect() {
    if(!this.element.matches('turbo-frame')) {
      return
    }
  }

  tabbarTargetConnected() {
    if(!this.element.matches('turbo-frame')) {
      return
    }
    this.tabber = this.element
    this.activeTabName = null
    this.buttonContainerSelector = "[data-tabber-target='tabbar'] ul.tabs"
    this.tabContainerSelector = 'div.tab-contents'
    this.scrollBehaviour = (window.location.href === document.referrer) ? 'smooth' : 'auto'

    this.scrollSidewaysToActiveTabButton()

    if(this.stickyContainer()) {
      this.setStickyTabs()

      const scrollToTab = this.queryStringTabName()

      if (this.buttonContainer() && scrollToTab) {
        this.scrollToActiveStickyTabContents()
      }

      document.onscroll = this._handleScroll.bind(this)

      document.addEventListener('navShying', (e) => {
        if (document.querySelector('.sticky-tabs.sticky-bottom.stuck')) {
          return
        }
        document.querySelector('.sticky-tabs').style.top = `${e.detail.yVisible}px`
      })
    }
  }

  elementOrFirstChildIs = (element, selector) => {
    return element.matches(selector) || this.firstChildIs(element, selector)
  }

  firstChildIs = (element, selector) => {
    if(!element.hasChildNodes) {
      return false
    }
    element.firstChild?.hasOwnProperty('matches') && element.firstChild.matches(selector)
  }


  elementIsOrContains = (element, selector) => {
    return element === element.parentElement.querySelector(selector)
  }

  scrollSidewaysToActiveTabButton = () => {
    const buttonContainerBounds = this.buttonContainer().getBoundingClientRect()
    const activeBtnBounds = this.activeTabButton().getBoundingClientRect()
    const scrollRight = (activeBtnBounds.x + activeBtnBounds.width) > buttonContainerBounds.width

    if(scrollRight) {
      this.buttonContainer().scrollTo({left: activeBtnBounds.x - (activeBtnBounds.width / 2), behavior: "instant"})
    }
  }

  scrollToActiveStickyTabContents = () => {
    if(!this.stickyContainer()){
      return
    }
    const shyNavHeight = document.querySelector('[data-shy-navigation]')?.offsetHeight || 0
    const scrollToElement = this.tabContainer()
    const tabsHeight = this.stickyContainer().offsetHeight
    const scrollToY = scrollToElement.getBoundingClientRect().top + window.scrollY
    const scrollOutBy = ((shyNavHeight + tabsHeight) - (window.scrollY - scrollToY))
    const scrollOutByMoreLessThan = 10

    if(scrollOutBy < scrollOutByMoreLessThan && scrollOutBy > (0 - scrollOutByMoreLessThan)) {
      return
    }

    const needsScrollUp = window.scrollY > scrollToY

    if(needsScrollUp) {
      window.scrollTo({top: scrollToY - shyNavHeight - tabsHeight, behavior: "smooth"})
    } else {
      window.scrollTo({top: scrollToY - tabsHeight, behavior: "smooth"})
    }
  }

  activeTabButton = () => {
    return this.tabber.querySelector('.tab-button.active')
  }

  tabContainer = () => {
    return this.tabber.querySelector(this.tabContainerSelector)
  }

  buttonContainer = () => {
    return this.tabber.querySelector(this.buttonContainerSelector)
  }

  stickyContainer = () => {
    const sticky_container = this.buttonContainer().closest('.sticky-tabs')
    if(sticky_container) {
      return sticky_container
    }
  }

  queryStringTabName = () => {
    const url = new URL(window.location.href)
    return url.searchParams.get('tab')
  }

  setStickyTabs () {
    const tabContainerBounds = this.tabContainer().getBoundingClientRect()
    const stickyContainer = this.stickyContainer()
    const stickyContainerBounds = stickyContainer.getBoundingClientRect()
    const stickyTabsContainerOffset = tabContainerBounds.y - stickyContainerBounds.height - stickyContainerBounds.y
    if(stickyContainer.classList.contains('sticky-bottom')) {
      if(tabContainerBounds.y < window.innerHeight) {
        stickyContainer.classList.remove('stuck')
      } else if(tabContainerBounds.y > window.innerHeight && !stickyContainer.classList.contains('stuck')) {
        stickyContainer.classList.add('stuck')
        stickyContainer.style.top = 0
      }
    }
    if(stickyContainer.classList.contains('sticky-top')) {
      if(stickyTabsContainerOffset < 0) {
        stickyContainer.classList.add('stuck')
      } else if(stickyTabsContainerOffset > 0 && stickyContainer.classList.contains('stuck')) {
        stickyContainer.classList.remove('stuck')
      }
    }

    if(stickyTabsContainerOffset < 0) {
      stickyContainer.classList.add('over-content')
    } else {
      stickyContainer.classList.remove('over-content')
    }
  }

  _handleScroll () {
    this.setStickyTabs()
  }
}
