import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="turbo-modal"
export default class extends Controller {
  connect() {
    this.element.classList.add('open')
    const body = this.element.closest('body')
    body.classList.add('with-modal')
  }
  disconnect() {
    const body = document.querySelector('body')
    body.classList.remove('with-modal')
  }
  hideModal(e) {
    if (e.cancelable) {
      e.preventDefault()
    }
    this.element.parentElement.removeAttribute("src")
    this.element.remove()
  }
}
