// This file creates new window event "optimizedResize", which should be used instead of resize for triggering any DOM
// manipulations. Refer to: https://developer.mozilla.org/en-US/docs/Web/Events/resize

const throttle = (type, name, obj) => {
  obj ||= window
  let running = false
  const func = () => {
    if(running) {
      return
    }

    running = true
    requestAnimationFrame(() => {
      const event = new CustomEvent(name)
      obj.dispatchEvent(event)
      running = false
      return running
    })
  }

  obj.addEventListener(type, func)
}

throttle('resize', 'optimizedResize')
