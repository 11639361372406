import { Controller } from "@hotwired/stimulus"

// Animates addition or removal of a DOM element using the classes defined on
// the element's dataset as streamEnterClass and streamExitClass
//
// Connects to data-controller="action-animation"

export default class extends Controller {
  connect() {
    this.streamAnimationHandler = this.streamAnimationHandler.bind(this)
    if (this.element.beforeRenderBound) {
      return
    }
    this.element.beforeRenderBound = true
    document.addEventListener("turbo:before-stream-render", this.streamAnimationHandler)
  }

  streamAnimationHandler(event) {
    let targetsSelector = event.target.targets
    let targetId = event.target.target
    let targetElements = []
    let action = event.target.action
    let templateElement = event.target.templateElement
    let templateContent = event.target.templateContent

    if (!['remove', 'append', 'prepend', 'replace'].includes(action)) {
      return
    }

    if (targetId && this.element.matches(`#${event.target.target}`)) {
      targetElements.push(this.element)
    } else if (targetId && this.element.querySelector(`#${event.target.target}`)) {
      targetElements.push(this.element.querySelector(`#${event.target.target}`))
    }
    if (targetsSelector) {
      targetElements = this.element.querySelectorAll(targetsSelector)
    }

    if (targetElements.length == 0) {
      return
    }

    targetElements.forEach((targetElement) => {
      if (action == "remove") {
        let streamExitClass = targetElement.dataset.streamExitClass
        let streamEnterClass = targetElement.dataset.streamEnterClass

        if (!streamExitClass) {
          return
        }

        targetElement.classList.remove(streamEnterClass)
        event.preventDefault()
        targetElement.classList.add(streamExitClass)

        targetElement.addEventListener("animationend", function(animationEvent) {
          if (animationEvent.animationName == streamExitClass) {
            event.target.performAction()
          }
        })

        let animationDuration = window.getComputedStyle(targetElement,null).getPropertyValue('animation-duration')
        if (parseFloat(animationDuration) <= 0) {
          //NB this exists to perform the action if the animation doesn't run
          event.target.performAction()
        }
      }
      if (action == "append" || action == "prepend") {
        let streamEnterClass = templateContent.firstElementChild.dataset.streamEnterClass

        if (streamEnterClass) {
          templateElement.content.firstElementChild.classList.add(streamEnterClass)
        }
      }
      if (action == "replace") {
        let streamReplaceClass = templateContent.firstElementChild.dataset.streamReplaceClass
        let replacementElement = templateElement.content.firstElementChild
        if (streamReplaceClass && replacementElement) {
          replacementElement.classList.add(streamReplaceClass)
        }
      }
    })
  }
}
