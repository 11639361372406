import loadBefore from "@/javascript/components/load"
import { closest } from "@/javascript/components/tools/closest"
import Loader from "@/javascript/components/loader"

!function(e){var n=!1;if("function"==typeof define&&define.amd&&(define(e),n=!0),"object"==typeof exports&&(module.exports=e(),n=!0),!n){var o=window.Cookies,t=window.Cookies=e();t.noConflict=function(){return window.Cookies=o,t}}}(function(){function g(){for(var e=0,n={};e<arguments.length;e++){var o=arguments[e];for(var t in o)n[t]=o[t]}return n}return function e(l){function C(e,n,o){var t;if("undefined"!=typeof document){if(1<arguments.length){if("number"==typeof(o=g({path:"/"},C.defaults,o)).expires){var r=new Date;r.setMilliseconds(r.getMilliseconds()+864e5*o.expires),o.expires=r}o.expires=o.expires?o.expires.toUTCString():"";try{t=JSON.stringify(n),/^[\{\[]/.test(t)&&(n=t)}catch(e){}n=l.write?l.write(n,e):encodeURIComponent(String(n)).replace(/%(23|24|26|2B|3A|3C|3E|3D|2F|3F|40|5B|5D|5E|60|7B|7D|7C)/g,decodeURIComponent),e=(e=(e=encodeURIComponent(String(e))).replace(/%(23|24|26|2B|5E|60|7C)/g,decodeURIComponent)).replace(/[\(\)]/g,escape);var i="";for(var c in o)o[c]&&(i+="; "+c,!0!==o[c]&&(i+="="+o[c]));return document.cookie=e+"="+n+i}e||(t={});for(var a=document.cookie?document.cookie.split("; "):[],s=/(%[0-9A-Z]{2})+/g,f=0;f<a.length;f++){var p=a[f].split("="),d=p.slice(1).join("=");this.json||'"'!==d.charAt(0)||(d=d.slice(1,-1));try{var u=p[0].replace(s,decodeURIComponent);if(d=l.read?l.read(d,u):l(d,u)||d.replace(s,decodeURIComponent),this.json)try{d=JSON.parse(d)}catch(e){}if(e===u){t=d;break}e||(t[u]=d)}catch(e){}}return t}}return(C.set=C).get=function(e){return C.call(C,e)},C.getJSON=function(){return C.apply({json:!0},[].slice.call(arguments))},C.defaults={},C.remove=function(e,n){C(e,"",g(n,{expires:-1}))},C.withConverter=e,C}(function(){})});
const csCookies = Cookies.noConflict();
const cookieScriptWindow = window.document;
const cookieScripts = document.getElementsByTagName("script");
const cookieScriptSrc = cookieScripts[cookieScripts.length - 1].src;
let cookieQuery = null;
const cookieScriptSource = "cookie-script.com";
const cookieScriptDomain = "";
const cookieScriptReadMore = "/static_pages/cookies";
const cookieId = "f361c08708fa42e9fc6c3961d7a010e1";
const cookieScriptDebug = 0;
const cookieScriptShowBadge=true;
const cookieScriptCurrentUrl = window.location.href;
const cookieScriptTitle = 'Hubbado uses cookies';
const cookieScriptDesc1 = 'We use cookies to provide you with the best possible service, and to continuously improve our website.';
const cookieScriptDesc2 = 'By clicking “OK” we will be able to personalise content for you. Click <a class="cookie-reject">No</a> to only place cookies for analytical purposes.';
const cookieScriptAccept = 'OK';
const cookieScriptMore = 'Your cookie settings';
const cookieScriptCopyrights = 'I agree';
//var cookieBackground='#111',
//var cookieTextColor='#FFF',

var cookieScriptLoadJavaScript = function (d, b) {
  var c = document.getElementsByTagName("head")[0],
    a = document.createElement("script");
  a.type = "text/javascript", a.src = d, b != undefined && (a.onload = a.onreadystatechange = function () {
    (!a.readyState || /loaded|complete/.test(a.readyState)) && (a.onload = a.onreadystatechange = null, c && a.parentNode && c.removeChild(a), a = undefined, b())
  }), c.insertBefore(a, c.firstChild)
};

var InjectCookieScript = function () {
  let cookieScriptStatsDomain
  if (window.location.protocol == "https:") cookieScriptStatsDomain = "";
  else                                      cookieScriptStatsDomain = "chs03.";

  const cookieScriptCreateCookie = function (n, t, i) {
    if (window.location.protocol == "https:")
      var cookieScriptSecureCookie = ";secure";
    else
      var cookieScriptSecureCookie = "";
    var u = "",
      r, f;
    i && (r = new Date, r.setTime(r.getTime() + i * 864e5), u = "; expires=" + r.toGMTString()), f = "", cookieScriptDomain != "" && (f = "; domain=" + cookieScriptDomain), document.cookie = n + "=" + t + u + f + "; path=/" + cookieScriptSecureCookie;
  };

  const cookieScriptReadCookie = function (n) {
    for (var r = n + "=", u = document.cookie.split(";"), t, i = 0; i < u.length; i++) {
      for (t = u[i]; t.charAt(0) == " ";) t = t.substring(1, t.length);
      if (t.indexOf(r) == 0) return t.substring(r.length, t.length)
    }
    return null
  };

  function addCookieBanner() {
    cookieQuery("#cookiescript_injected", cookieScriptWindow).remove();  
    const cookieScriptAddBox = function () {
      cookieQuery("body", cookieScriptWindow).append(
        '<div class="cookie-overlay"></div>' +
        '<div id="cookiescript_injected"><div id="cookiescript_wrapper">' + 
          '<div class="info">' +
          '  <h1>' + cookieScriptTitle + '</h1>' +
          '  <p>' + cookieScriptDesc1 + '</p>' +
          '  <p>' + cookieScriptDesc2 + '</p>' +
          '</div>' +
          '<div class="cookie-buttons btn-container always-column"><div class="cookie-accept btn primary">' + cookieScriptAccept + '</div>' +
          '<a id="cookiescript_readmore" class="modal-link cookie-modal btn" data-modal data-title="Cookie Policy" data-url="' + cookieScriptReadMore + '">' + cookieScriptMore + '</a></div>' +
          '<div id="cookiescript_pixel">' +
        '</div></div>'
      );

      if(window._gaq) { _gaq.push(['_trackEvent', 'Cookie-Script', 'Show', {'nonInteraction': 1}]) }
      if(window.ga) { ga('send', 'event', 'Cookie-Script', 'Show', {'nonInteraction': 1}); }
      if(window.gtag) { gtag('event', 'Show', {'event_category':'Cookie-Script'}); }
      cookieQuery("#cookiescript_injected", cookieScriptWindow).fadeIn(50);
      cookieQuery(".cookie-overlay", cookieScriptWindow).fadeIn(50);

      document.addEventListener('click', function(e) {
        const target = e.target;
        if(closest(target, '#cookiescript_readmore') == null) return null;

        if(window._gaq){_gaq.push(['_trackEvent', 'Cookie-Script', 'Read more', {'nonInteraction': 1}])}
        if(window.ga){ga('send', 'event', 'Cookie-Script', 'Read more', {'nonInteraction': 1});}
        if(window.gtag){gtag('event', 'Read more', {'event_category':'Cookie-Script'});}
      })
    }

    const cookieScriptCurrentValue = cookieScriptReadVar("action");

    if(cookieScriptCurrentValue == "accept") {
      cookieScriptAllowJS();
    }
    if(cookieScriptCurrentValue == "accept" || cookieScriptCurrentValue == "reject") {
      cookieScriptShowBadgeFunction();
      return false;
    }
    cookieScriptAddBox();
    cookieScriptBindButtons();
    new Loader(document.getElementById('cookiescript_injected')).functional();
  }

  function cookieScriptAcceptFunction() {
    cookieQuery("#cookiescript_injected", cookieScriptWindow).fadeOut(50);
    cookieQuery(".cookie-overlay", cookieScriptWindow).fadeOut(50);
    cookieScriptAddVar("action", "accept");
    cookieScriptAllowJS();
    cookieQuery("#csconsentcheckbox").prop("checked", true);
    let cookieScriptShowBadge
    if (typeof cookieScriptShowBadge === "undefined") cookieScriptShowBadge=true;
    if(!cookieScriptShowBadge) return false;
    cookieScriptShowBadgeFunction();
  }

  function cookieScriptRejectFunction() {
    cookieScriptAddVar("action", "reject");
    cookieScriptClearAllCookies();
    cookieQuery("#cookiescript_injected", cookieScriptWindow).fadeOut(50);
    cookieQuery(".cookie-overlay", cookieScriptWindow).fadeOut(50);
    cookieQuery("#csconsentcheckbox").prop("checked", false);
    let cookieScriptShowBadge
    if (typeof cookieScriptShowBadge === "undefined") cookieScriptShowBadge=true;
    if(!cookieScriptShowBadge) return false;
    cookieScriptShowBadgeFunction();
  }

  function cookieScriptAddVar(name,value){
    var cookieConsentName="CookieScriptConsent";
    try {
      var jsonCookie=JSON.parse(cookieScriptReadCookie(cookieConsentName));
    } catch(e) {
      console.log(e);
      return false;
    }

    if(jsonCookie==null) {
      var jsonCookie={};
      jsonCookie[name]=value
    } else { //todo Add is object
      jsonCookie[name]=value;
    }
    try {
      var newCookieVal=JSON.stringify(jsonCookie);
    } catch(e){
      console.log(e);
      return false;
    }
    cookieScriptCreateCookie(cookieConsentName, newCookieVal, 30);
  }

  function cookieScriptReadVar(name){
    var cookieConsentName="CookieScriptConsent";
    try {
      var jsonCookie=JSON.parse(cookieScriptReadCookie(cookieConsentName));
    } catch(e) {
      console.log(e);
      return null;
    }

    if(jsonCookie==null) {
      return null;
    } else { //todo Add is object
      return jsonCookie[name];
    }
  }
  function cookieScriptShowBadgeFunction() {
    return false;
  }
  function cookieScriptShowConsent() {
    if (cookieQuery("#cookiescript_badge").length) {
      cookieQuery("#cookiescript_badge", cookieScriptWindow).fadeOut(50);
    }

    if (cookieQuery("#cookiescript_injected").length) {
      cookieQuery("#cookiescript_injected", cookieScriptWindow).fadeIn(50);
      cookieQuery(".cookie-overlay", cookieScriptWindow).fadeIn(50);
    } else {
      cookieScriptAddBox();
    }
  }

  function cookieScriptDebug(text) {
    if (cookieScriptDebug) console.log(text);
  }

  function cookieScriptBindButtons(){
    document.addEventListener('click', function(e) {
      const target = e.target;
      if(closest(target, '.cookie-accept') == null) return null;

      if(window._gaq) { _gaq.push(['_trackEvent', 'Cookie-Script', 'Accept', {'nonInteraction': 1}]) }
      if(window.ga) { ga('send', 'event', 'Cookie-Script', 'Accept', {'nonInteraction': 1}); }
      if(window.gtag) { gtag('event', 'Accept', {'event_category':'Cookie-Script'}); }

      cookieScriptAcceptFunction();
    });

    document.addEventListener('click', function(e) {
      const target = e.target;
      if(closest(target, '.cookie-reject') == null) return null;
      cookieScriptRejectFunction();
    });
  }

  function cookieScriptClearAllCookies(){
    var cookies = csCookies.get();
    for(var cookie in cookies) {
      if(cookie=='CookieScriptConsent') { continue; }

      if(cookie=='_hubbado_session') { continue; }
      if(!csCookies.remove(cookie)) {
        var cookiePossibleHosts=[window.location.host, '.'+window.location.host];
        //now removing subdomains
        var regexParse = new RegExp('[a-z\-0-9]{2,63}\.[a-z\.]{2,6}$');
        var urlParts = regexParse.exec(window.location.host);
        var cookieSubdomain = '';

        let cookierSubdomain
        if (urlParts !== null) {
          cookierSubdomain = window.location.host.replace(urlParts[0],'').slice(0, -1);
        }

        if(cookieSubdomain != '') { 
          cookiePossibleHosts.push(window.location.host.substr(cookieSubdomain.length));
        }

        for(var cookiePossibleHost in cookiePossibleHosts) {
          if(csCookies.remove(cookie, { path: '/',domain: cookiePossibleHosts[cookiePossibleHost] }) && cookieScriptDebug)
            console.log('deleting cookie:'+cookie+'| domain:'+cookiePossibleHosts[cookiePossibleHost]);
        }
      }
    }
  }

  if(cookieScriptReadVar("action") != "accept")
    setTimeout(cookieScriptClearAllCookies,500);

  function cookieScriptAllowJS() {
    cookieQuery('img'+'[data-cookiescript="accepted"]').each(function () {
      cookieQuery(this).attr('src', cookieQuery(this).attr("data-src"));
    });

    cookieQuery('script'+'[type="text/plain"]'+'[data-cookiescript="accepted"]').each(function () {
      if (cookieQuery(this).attr('src')) {
        cookieQuery(this).after('<script type="text/javascript" src="' + cookieQuery(this).attr("src") + '"><\/script>')
      } else {
        cookieQuery(this).after('<script type="text/javascript">' + cookieQuery(this).html() + '<\/script>')
      }
      cookieQuery(this).empty()
    });

    cookieQuery('iframe'+'[data-cookiescript="accepted"]').each(function () {
      cookieQuery(this).attr('src', cookieQuery(this).attr("data-src"));
    });

    cookieQuery('embed'+'[data-cookiescript="accepted"]').each(function () {
      cookieQuery(this).replaceWith(cookieQuery(this).attr('src',cookieQuery(this).attr("data-src"))[0].outerHTML);
    });

    cookieQuery('object'+'[data-cookiescript="accepted"]').each(function () {
      cookieQuery(this).replaceWith(cookieQuery(this).attr('data',cookieQuery(this).attr("data-data"))[0].outerHTML);
    });

    cookieQuery('link'+'[data-cookiescript="accepted"]').each(function () {
      cookieQuery(this).attr('href', cookieQuery(this).attr("data-href"));
    });
  }

  loadBefore(addCookieBanner);
};

if(document.querySelector('body.test-environment') === null) {
  window.jQuery && jQuery.fn && /^(1\.[8-9]|2\.[0-9]|1\.1[0-2]|3\.[0-9])/.test(jQuery.fn.jquery) ? (cookieScriptDebug && window.console && console.log("Using existing jQuery version " + jQuery.fn.jquery), cookieQuery = window.jQuery, InjectCookieScript()) : (cookieScriptDebug && window.console && console.log("Loading jQuery 1.8.1 from ajax.googleapis.com"), cookieScriptLoadJavaScript(("https:" == document.location.protocol ? "https://" : "http://") + "ajax.googleapis.com/ajax/libs/jquery/1.8.1/jquery.min.js", function () {cookieQuery = jQuery.noConflict(!0), InjectCookieScript();}));
}
