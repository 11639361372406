import { Controller } from "@hotwired/stimulus"
import { set } from "lodash"

// Animates addition or removal of a DOM element using the classes defined on
// the element's dataset as streamEnterClass and streamExitClass
//
// Connects to data-controller="timesheet-review"

export default class extends Controller {
  static targets = [ "block" ]

  connect() {
    this.loadedEmptyList = this.element.querySelectorAll('.contractors-timesheets').length == 0
  }

  blockTargetDisconnected(){
    this.removeContractorsWithoutTimesheets()
  }

  reloadOnEmptyList() {
    const nowEmpty = this.element.querySelectorAll('.contractors-timesheets').length == 0
    const innerListElement = this.element.querySelector('.item-list .inner-list')
    if (!this.loadedEmptyList && nowEmpty) {
      innerListElement.classList.add('reloading-list')
      this.element.ariaBusy = true
      setTimeout(() => {
        this.reloadframe()
      }, 400)
    }
  }

  removeContractorsWithoutTimesheets() {
    let contractorContainers = this.element.querySelectorAll('.contractors-timesheets')
    contractorContainers.forEach((contractorContainer) => {
      if (contractorContainer.querySelectorAll('.timesheet-block').length == 0) {
        contractorContainer.addEventListener("animationend", (event) => {
          event.target.remove()
          this.reloadOnEmptyList()
        })
        contractorContainer.classList.add('grid-slide-up')
      }
    })
  }

  reloadframe() {
    const frame = this.element
    if (!frame.hasAttribute("src")) {
      frame.src = window.location.href
    }
    frame.autoscroll = false
    frame.reload()
    frame.addEventListener("turbo:frame-load", () => {
      frame.autoscroll = true
    })
  }

  toggleReviewerDisabled(event){
    const onlyMeCheckbox = event.target
    const reviewerSelect = this.element.querySelector('#timesheet_reviewer_ids')
    if (!onlyMeCheckbox || !reviewerSelect) {
      return
    }
    if (onlyMeCheckbox.checked) {
      reviewerSelect.selectize.setValue(onlyMeCheckbox.dataset.currentUserId)
      reviewerSelect.selectize.disable()
    } else {
      reviewerSelect.selectize.enable()
    }
  }
}
