import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="turbo-signaling"
export default class extends Controller {
  static targets = [ "signal" ]

  start = Date.now();

  debounce = (callback, wait, timerId) => {
    return (...args) => {
      window.clearTimeout(timerId);
      const promiseForFunc = new Promise((resolve) => {
        timerId = window.setTimeout(resolve, wait);
      });

      return promiseForFunc.then(() => callback(...args));
    };
  }

  signalTargetConnected(element){
    element.dataset.signalRecieved = Date.now()
    this.timerId = element.dataset.modelId
    this.sendSignal(element)
  }

  sendSignal = this.debounce((element) => {
        const form = this.element;
        const url = new URL(form.action);
        url.searchParams.set('signal_model_id', element.dataset.modelId);
        form.action = url.toString();
        form.requestSubmit();
        element.dataset.signalSent = Date.now();
    }, 250, this.timerId)
}
