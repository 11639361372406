import Loader from "@/javascript/components/loader"
import { Toggler } from "@/javascript/components/toggling"
import loadBefore from "@/javascript/components/load";

Loader.functional((content, resolve) => {
  content.querySelectorAll('.js-faqs-list .js-faq-toggle').forEach((faqLink) => {
    if(faqLink.toggleEnabled) {
      return
    }
    faqLink.toggleEnabled = true

    const faq = content.querySelector('.js-faq-item' + faqLink.hash)
    if(!faq) {
      return
    }

    if(faqLink.classList.contains('js-always-open')) {
      faqLink.toggler = new Toggler(faqLink, faq, (visible, animation, elem, container) => {
        container.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
        window.history.pushState({ href: elem.href }, elem.innerText, elem.href)
      }, {always: 'open'})
    } else {
      faqLink.toggler = new Toggler(faqLink, faq, (visible, animation, elem, container) => {
        if(visible !== true) {
          container.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
        }
        window.history.pushState({ href: elem.href }, elem.innerText, elem.href)
      })
    }
  })
  resolve()
})

loadBefore(() => {
  const navto = window.location.hash
  if(!navto) {
    return
  }

  const faqLink = document.querySelector(`.js-faqs-list .js-faq-item > a.js-faq-toggle[href="${navto}"]`)
  if(!faqLink) {
    return
  }

  faqLink.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
  faqLink.toggler.toggle()
})
