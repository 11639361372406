
import { Controller } from "@hotwired/stimulus"


/* Connects to data-controller="table"
 *
 * This collapses empty columns in tables by adding the CSS class collasped to
 * th and td elements in an empty column. This is needed because rows can be
 * dynamically added and removed from a table via turbo.
 *
 * The collapsed class should be styled to display none.
 *
 * The stimulus controller does not run on the first page render, so columns
 * headers that should be collasped need to be set to collapsed
 *
 * table {
 *   th, td {
 *     &.collasped {
 *       display: none;
 *     }
 *   }
 * }
 *
 * The table is defined like this:
 *
 * table data-controller="table"
 *   thead
 *     tr
 *       th Some heading
 *       th.collapsed Some hidden heading
 *   tbody
 *     tr data-table-target="collapseColumns"
 *       td Some value
 *       # No value here - will be collasped
 *       td 
 */
export default class extends Controller {
  static targets = ["collapseColumns"]

  collapseColumnsTargetConnected() {
    this.toggleCollapseEmptyColumns()
  }

  collapseColumnsTargetDisconnected() {
    this.toggleCollapseEmptyColumns()
  }

  toggleCollapseEmptyColumns() {
    const headerCells = this.element.querySelectorAll('thead tr th')
    const bodyRows = this.element.querySelectorAll('tbody tr')

    headerCells.forEach((headerCell, index) => {
      let emptyColumnCells = this.element.querySelectorAll('tbody tr td:nth-of-type(' + (index + 1) + '):empty')
      if(emptyColumnCells.length == bodyRows.length) {
        headerCell.classList.add('collapsed')

        emptyColumnCells.forEach((columnCell) => {
          columnCell.classList.add('collapsed')
        })
      } else {
        headerCell.classList.remove('collapsed')

        emptyColumnCells.forEach((columnCell) => {
          columnCell.classList.remove('collapsed')
        })
      }
    })
  }
}
