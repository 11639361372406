import { Controller } from "@hotwired/stimulus"

// Update the target element id once it has moved tabs
// to prevent further updates of the element from the backend
//
// Connects to data-controller="move-tabs"

export default class extends Controller {
  static targets = [ "detach" ]

  detachTargetConnected(){
    if (this.element.id.includes("tab-detached")){
      return
    }
    this.element.id = `${this.element.id}-${"tab-detached"}`
  }
}
