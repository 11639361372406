import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="turbo-modal-frame"
export default class extends Controller {
  frameMissing(e) {
    if(e.detail.response.redirected == true) {
      Turbo.visit(e.detail.response.url, { action: 'replace' })
      return
    }
    e.preventDefault()

    const devEnvironment = document.querySelector('body.dev-environment') == null ? false : true
    const modalElement =  document.querySelector('#turbo_modal') || this.createModal()
    const container = modalElement.querySelector('article')

    if (!devEnvironment){
      container.classList.add('modal-load-error')
      container.innerHTML = 'Content could not be loaded.'
    } else {
      container.classList.add('dev-modal-load-error')
      container.innerHTML = 'Tubro-frame missing. Response: ' + e.detail.response.status + ' ' + e.detail.response.statusText
    }
  }

  createModal() {
    const modalElement = document.createElement('div')
    const containerWrapper = document.createElement('div')
    const container = document.createElement('article')
    const closeButton = document.createElement('a')

    modalElement.classList.add('turbo-modal')
    modalElement.id = 'turbo_modal'
    containerWrapper.classList.add('container-wrapper')
    closeButton.classList.add('modal-close')
    closeButton.dataset.action = 'turbo-modal#hideModal'

    modalElement.appendChild(closeButton)
    containerWrapper.appendChild(container)
    modalElement.appendChild(containerWrapper)
    this.element.appendChild(modalElement)

    modalElement.dataset.controller = 'turbo-modal'

    return modalElement
  }
}
