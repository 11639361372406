import { Controller } from "@hotwired/stimulus"

// Enables an aria-busy state on the controller element when a
// .btn-container > a.btn is clicked
// NB: links with role="button" or class="turbo-modal-link" are excluded
//
// Connects to data-controller="busy"

export default class extends Controller {
  connect() {
    this.setBtns()
    this.setForms()
  }

  setForms(){
    this.element.querySelectorAll('form').forEach((form) => {
      if (!form.dataset['action']) {
        form.dataset['action'] = ''
      }
      form.dataset['action'] += 'turbo:submit-start->busy#setContainerBusy turbo:submit-end->busy#setContainerNotBusy'
    })
  }

  setBtns(){
    this.getBtnContainers().forEach((btnContainer) => {
      btnContainer.querySelectorAll('a.btn:not([role="button"], .turbo-modal-link)').forEach((btn) => {
        if (!btn.dataset['action']) {
          btn.dataset['action'] = ''
        }
        btn.dataset['action'] += 'click->busy#setContainerBusy '
      })
    })
  }

  getBtnContainers() {
    let btnContainers =  []

    if(this.element.matches('.btn-container')) {
      btnContainers.push(this.element)
    }else if(this.element.querySelector('.btn-container')) {
      this.element.querySelectorAll('.btn-container').forEach((btnContainer) => {
        btnContainers.push(btnContainer)
      })
    }
    return btnContainers
  }

  setContainerBusy() {
    this.element.setAttribute('aria-busy', 'true')
  }

  setContainerNotBusy() {
    this.element.setAttribute('aria-busy', 'false')
  }
}
