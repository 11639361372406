import { Controller } from "@hotwired/stimulus"

//
//
// Connects to data-controller="dependant-fields"

export default class extends Controller {
  static targets = [ "" ]

  connect() {
    this.dependantFieldSelector = '[data-controller="dependant-field"]'
    this.element.tagName == 'form' ? this.form = this.element : this.form = this.element.closest('form')
    this.token = document.querySelector('meta[name="csrf-token"]').content;
    this.element.addEventListener('change', this.formChanged.bind(this))
    this.formTouched = false
  }

  dependantFieldController(element) {
    return this.application.getControllerForElementAndIdentifier(element, 'dependant-field')
  }

  setFieldsToWatch() {
    this.dependantFields = this.element.querySelectorAll(this.dependantFieldSelector)
    this.watchedFieldDetails = []
    this.fieldsToWatch = []
    this.dependantFields.forEach(dependantField => {
      let fieldController = this.dependantFieldController(dependantField)
      this.fieldsToWatch.push(...fieldController.parentFieldElements())
      this.watchedFieldDetails.push({
        dependantField: fieldController.dependantElement(),
        field: fieldController.innerField(),
        url: fieldController.reRenderUrl(),
        fieldsToWatch: fieldController.parentFieldElements()
      })
    })
    this.fieldsToWatch = [...new Set(this.fieldsToWatch.flat())].filter((el) => el != null)
  }

  fieldConnected(field) {
    if (this.formTouched) {
      this.checkWatchedFields(field)
    }
  }

  allDependenciesForField(field) {
    let dependencies = []
    this.watchedFieldDetails.forEach(fieldDetails => {
      if(fieldDetails.fieldsToWatch.find((element) => element.id == field.id)){
        dependencies.push(fieldDetails.field)
        dependencies.push(this.allDependenciesForField(fieldDetails.field))
      }
    })
    return [...new Set(dependencies.flat())].filter((el) => el != null)
  }

  checkWatchedFields(field) {
    this.setFieldsToWatch()
    if(!this.fieldsToWatch.find((element) => element.id == field.id)){
      return
    }
    this.watchedFieldDetails.forEach(fieldDetails => {
      let allDependencies = this.allDependenciesForField(field)
      if(allDependencies){
        allDependencies.forEach(dependency => {
          let turboTarget = dependency.closest('turbo-target')
          if (turboTarget) {
            turboTarget.ariaBusy = true
          } else {
            dependency.ariaBusy = true
          }
        })
      }
      if(fieldDetails.fieldsToWatch.find((element) => element.id == field.id)){
        this.fetchField(fieldDetails.url)
      }
    })
  }

  formChanged(event) {
    this.formTouched = true
    this.checkWatchedFields(event.target)
  }

  fetchField(url) {
    let formData = new FormData(this.form)
    let params = new URLSearchParams()
    formData.forEach((value, key) => {
      if(key=='_method'){
        return
      }
      params.set(key, value)
    })

    fetch(url, {
      method: "POST",
      headers: {
        Accept: "text/vnd.turbo-stream.html",
        "X-CSRF-Token": this.token
      },
      credentials: 'same-origin',
      body: params
    }).then (response => response.text())
    .then(html => Turbo.renderStreamMessage(html))
  }
}
