/*
 This plugin extends lazySizes to lazyLoad: esm scripts

 For esm scripts use data-module-script:
 <div class="lazyload" data-module-script="module-name.js"></div>
*/

(function(window, factory) {
    var globalInstall = function(){
        factory(window.lazySizes);
        window.removeEventListener('lazyunveilread', globalInstall, true);
    };

    factory = factory.bind(null, window, window.document);

    if(typeof module == 'object' && module.exports){
        factory(require('lazysizes'));
    } else if (typeof define == 'function' && define.amd) {
        define(['lazysizes'], factory);
    } else if(window.lazySizes) {
        globalInstall();
    } else {
        window.addEventListener('lazyunveilread', globalInstall, true);
    }
}(window, function(window, document, lazySizes) {
    /*jshint eqnull:true */
    'use strict';
    var uniqueUrls = {};

    if(document.addEventListener){
        addEventListener('lazybeforeunveil', function(e){
            if(e.detail.instance != lazySizes){return;}

            var tmp, load;
            if(!e.defaultPrevented) {

                var target = e.target;

                if(target.preload == 'none'){
                    target.preload = target.getAttribute('data-preload') || 'auto';
                }

                // handle data-script
                tmp = target.getAttribute('data-module-script');
                if(tmp){
                    e.detail.firesLoad = true;
                    load = function(){
                        e.detail.firesLoad = false;
                        lazySizes.fire(target, '_lazyloaded', {}, true, true);
                    };
                    addScript(tmp, load);
                }
            }
        }, false);

    }

    function addScript(src, cb){
        if(uniqueUrls[src]){
            return;
        }
        var elem = document.createElement('script');
        var insertElem = document.getElementsByTagName('script')[0];

        elem.onload = function(){
            elem.onerror = null;
            elem.onload = null;
            cb();
        };
        elem.onerror = elem.onload;
        elem.src = src;
        elem.type = "module"

        uniqueUrls[src] = true;
        uniqueUrls[elem.src] = true;
        insertElem.parentNode.insertBefore(elem, insertElem);
    }
}));
