import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  // This is called from Common::RefreshPrompt::Cell::Show
  // Pass in the frame ids in order of priority to be refreshed.
  static values = { frameIds: String }
  connect() {
    setTimeout(() => {
      this.element.classList.add("show")
    }, 250)

    setTimeout(() => {
      this.element.classList.add("pulse")
    }, 650)
  }
  refreshPage(e) {
    if (e.cancelable) {
      e.preventDefault()
    }

    let frame = this.frameIdsValue.split(',').map(id => {
      return document.querySelector('turbo-frame#' + id)
    }).find(x => !!x)

    if (frame) {
      if (!frame.getAttribute('src')) {
        frame.src = window.location.href
      }
      frame.reload()
    } else {
      window.location.reload()
    }
  }
}
