import { slideUp, slideDown, slideToggle } from "@/javascript/components/animations/slide"
import { closest } from "@/javascript/components/tools/closest"

const shyNavigations = []

class ShyNavigation {
  constructor (element) {
    this.element = element
    this.container = this.element.parentElement
    this.prevScroll = this.container.scrollTop
    this.container.onscroll = this._handleScroll.bind(this)

    this.element.style.top = '0'
    this._reposition()
    shyNavigations.push(this)
  }

  _handleScroll () {
    const newScroll = this._topScroll()
    if(newScroll === 0) {
      this._toggleDocTop(true)
    }
    if(newScroll < 0) {
      return
    }
    const scrollChange = newScroll - this.prevScroll
    this.prevScroll = newScroll
    const currentTop = parseInt(this.element.style.top)
    let newTop = currentTop - scrollChange
    if(newTop > 0) {
      newTop = 0
    }
    if(newTop < -this.navHeight) {
      newTop = -this.navHeight
    }

    if(newTop !== currentTop) {
      if((this.navHeight + newTop) === 0) {
        this.element.dispatchEvent(new CustomEvent('navShyed', {bubbles: true}))
      }
      this.element.style.top = `${newTop}px`

      const bounds = this.element.getBoundingClientRect()
      const yVisible = bounds.height + bounds.top
      const event = new CustomEvent('navShying', {bubbles: true, cancelable: false, detail: {yVisible: yVisible}})
      this.element.dispatchEvent(event)
    }
    if(newTop < 0) {
      this._toggleDocTop(false)
    }
  }

  _topScroll() {
    let scroll = this.container.scrollTop
    if(this.container == document.body) {
      scroll ||= document.documentElement.scrollTop
    }
    return scroll
  }

  _toggleDocTop (doctop) {
    this.element.classList.toggle('doc-top', doctop)
  }

  _reposition() {
    let newHeight = this.element.offsetHeight
    newHeight = newHeight - this.element.querySelector('.application-flash').offsetHeight
    if(newHeight == this.navHeight) {
      return
    }
    this.navHeight = newHeight
    if(this.container.classList.contains('overlay-nav')) {
      this._toggleDocTop(true)
    }
  }
}

document.addEventListener("DOMContentLoaded", () => {
  document.querySelectorAll('[data-shy-navigation]').forEach((element) => {
    element.shyNavigation = new ShyNavigation(element)
  })
})

window.addEventListener('optimizedResize', () => {
  shyNavigations.forEach((nav) => nav._reposition())
})

let openDropdown = null

const open = (elem) => {
  close()
  slideDown(elem, 200).then(() => openDropdown = elem)
}

const close = () => {
  if(!openDropdown) {
    return
  }

  slideUp(openDropdown, 200)
  const aToggle = openDropdown.parentElement.querySelector('a.dropdown-toggle')
  aToggle.classList.remove('open')
  aToggle.setAttribute('aria-expanded', false)
  openDropdown = null
}

const isInOpenDropdown = (elem) => {
  if(!openDropdown) {
    return false
  }

  let result = false
  while(elem) {
    if (elem == openDropdown) {
      result = true
      break
    }
    elem = elem.parentNode
  }
  return result
}

let mobileMenu = null
document.addEventListener('click', (e) => {
  const target = e.target
  const elem = closest(target, '.mobile-menu-toggle')
  if(!elem) {
    return
  }

  mobileMenu ||= document.querySelector('nav.mobile-navigation')
  slideToggle(mobileMenu, 300).then(() => {
    if(mobileMenu.offsetHeight) {
      mobileMenu.classList.add('open')
      mobileMenu.style.removeProperty('display')
    } else {
      mobileMenu.classList.remove('open')
    }
  })
  elem.classList.toggle('open')
  document.querySelector('body').classList.toggle('with-modal')
})

document.addEventListener('click', (e) => {
  const target = e.target
  const elem = closest(target, '.dropdown-toggle')

  if(!elem) {
    if (!isInOpenDropdown(target)) {
      close()
    }
    return
  }

  e.preventDefault()
  const isMobileNav = !!closest(elem, 'nav.mobile-navigation')

  const targetDropdown = elem.parentNode.querySelector('.nav-dropdown')
  elem.classList.toggle('open')

  if(isMobileNav) {
    slideToggle(targetDropdown, 300, true)
  } else {
    const  alreadyOpen = targetDropdown == openDropdown
    if(alreadyOpen) {
      close(targetDropdown)
    } else {
      elem.setAttribute('aria-expanded', true)
      open(targetDropdown)
    }
  }
})

document.addEventListener('navShyed', (e) => close())
