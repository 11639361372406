import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="cloudflare-turnstile"
export default class extends Controller {
  connect() {
    if(!this.element.classList.contains('cf-turnstile')){
      return
    }
    if (!this.element.querySelector('[name=cf-turnstile-response]') && window.turnstile) {
      window.turnstile.render(this.element)
    }
  }
}
