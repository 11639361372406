import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="flash"
// Controller is only responsible for removing flash messages
// to avoid multipule flash messages on the page

export default class extends Controller {
  static targets = [ "flash" ]

  flashTargetConnected(element){
    // Remove other flash messages, but not persistent ones
    this.element.childNodes.forEach((childElm) => {
      if (element === childElm || childElm.nodeType !== 1) {
        return
      }
      if (childElm.classList.contains('flash')) {
        childElm.addEventListener('animationend', () => {
          childElm.remove()
        })
        childElm.classList.add('fade-out-down')
      }
    })
  }
}
