import { Controller } from "@hotwired/stimulus"

//
//
// Connects to data-controller="dependant"

export default class extends Controller {
  static values = {
    parentfields: String,
    url: String
  }

  connect() {
    this.form = this.element.closest('form')
    this.parentFieldsController = this.application.getControllerForElementAndIdentifier(this.form, 'dependant-fields')
    this.parentFieldsController.fieldConnected(this.innerField())
  }

  dependantElement() {
    return this.element
  }

  innerField() {
    let fieldsSelector = ['input', 'select', 'button']
    if (fieldsSelector.includes(this.element.tagName.toLowerCase())) {
      return this.element
    }
    return this.element.querySelector(fieldsSelector.join(','))
  }

  parentFieldElements() {
    let fieldElements = []
    this.parentfieldsValue.split(',').forEach(parentName => {
      fieldElements.push(this.form.querySelector(`[name$="[${parentName}]"]`))
    });
    return fieldElements.filter((el) => el != null)
  }

  reRenderUrl() {
    return this.urlValue
  }
}
