import loadBefore from "@/javascript/components/load";

loadBefore(() => {
  //TODO: can this now be removed that we have global smooth scrolling?
  //Can't find anywhere it is in use.
  const urlParams = new URLSearchParams(window.location.search)
  const smooth_scroll_to = document.querySelector(urlParams.get('smooth_scroll_to'))
  if(smooth_scroll_to) {
    smooth_scroll_to.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
  }
})
