import { Controller } from "@hotwired/stimulus"

//
//
// Connects to data-controller="dialog"

export default class extends Controller {
  dialogTargetConnected(element){
    const sibling = element.nextElementSibling
    if(!sibling.matches('[role="button"]')){
      return
    }
    if (!sibling.dataset['action']) {
      sibling.dataset['action'] = ''
    }
    sibling.dataset['action'] += 'click->dialog#openDialog'
    element.querySelectorAll('.btn, .close').forEach((btn) => {
      if (!btn.dataset['action']) {
        btn.dataset['action'] = ''
      }
      btn.dataset['action'] += 'click->dialog#closeDialog '
    })
  }

  closeDialog(event) {
    const dialog = event.target.closest('dialog')
    if(!dialog.matches('dialog')){
      return
    }
    dialog.close();
  }

  openDialog(event) {
    const dialog = event.target.previousElementSibling
    if(!dialog.matches('dialog')){
      return
    }
    dialog.showModal();
    event.preventDefault();
  }
}
