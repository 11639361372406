import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  // This is called from Rails controllers using Concern::TurboStreamRedirect
  static values = { url: String, frame: String }
  connect() {
    if (this.frameValue) {
      const preventHistoryUpdate = this.frameValue == "modal"
      Turbo.visit(this.urlValue, { historyChanged: preventHistoryUpdate, frame: this.frameValue })
    } else {
      window.location.href = this.urlValue
    }
    this.element.remove()
  }
}
