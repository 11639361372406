import "./optimized_resize"
import Loader from "@/javascript/components/loader"

// Equalizer is responsible for making given elements even height regardless of their dynamic content
// Each equalizer can have multiple root elements. Root element is a container of items that are to be equalized.
// When having multiple roots equalizer will handle roots depending on their top alignment, i.e. it will not try to equalize
// elements within rootElements that are in different lines.
//
// USAGE:
//
// 1. Add data-equalizer="name" to the root element. name is optional, but recommended.
// 2. Add data-equalize="propName" to any element you want to be equalized within the root. Those properties accross
//    all the roots with same name will be kept at the same height.
//

class RootElem {
  constructor(elem) {
    this.elem = elem
    this.props = {}
  }
  addProp (name, elem) {
    this.props[name] = elem
  }

  offset() {
    return this.elem.offsetTop
  }
}

class Equalizer {
  static get(name) {
    const found = this.all.find((equalizer) => equalizer.name === name)

    return found || new Equalizer(name)
  }

  static equalize(container) {
    this.all.forEach((eq) => {
      eq.equalize(container)
    })
  }

  constructor(name) {
    this.name = name
    this.roots = []
    this.constructor.all.push(this)
  }

  addRootElem (elem) {
    const rootElem = new RootElem(elem)
    this.roots.push(rootElem)
    return rootElem
  }

  equalize (container) {
    this._groupRoots(container).forEach((group) => {
      this._equalizeGroup(group)
    })
  }

  _groupRoots (container) {
    container ||= document.body
    const result = {}

    this.roots.forEach((root) => {
      if(container.contains(root.elem) && root.elem.offsetParent) {
        (result[root.offset()] ||= []).push(root)
      }
    })

    const resultRootGroups = []
    Object.values(result).forEach((rootGroup) => {
      resultRootGroups.push(rootGroup)
    })

    return resultRootGroups
  }

  _equalizeGroup (rootGroup) {
    const groupProps = this._getAllGroupProps(rootGroup)
    Object.values(groupProps).forEach((elems) => {
      this._equalizeElems(elems)
    })
  }

  _getAllGroupProps (rootGroup) {
    const allProps = {}

    rootGroup.forEach((root) => {
      Object.entries(root.props).forEach((entry) => {
        const [name, elem] = entry;
        (allProps[name] ||= []).push(elem)
      })
    })

    return allProps
  }

  _equalizeElems (elems) {
    if(elems.length === 1) {
      return elems[0].style.removeProperty('height')
    }

    let maxHeight = 0
    elems.forEach((elem) => {
      elem.style.removeProperty('height')
    })

    elems.forEach((elem) => {
      const height = elem.clientHeight
      if (height > maxHeight) {
        maxHeight = height
      }
    })

    elems.forEach((elem) => {
      elem.style.setProperty('height', `${maxHeight}px`)
    })
  }
}

Equalizer.all = []

const equalizerLoader = (content) => {
  content.querySelectorAll('[data-equalizer]').forEach((elem) => {
    const rootElem = Equalizer.get(elem.dataset.equalizer).addRootElem(elem)
    if(elem.dataset.equalizer) {
      rootElem.addProp(elem.dataset.equalize, elem)
    }

    elem.querySelectorAll('[data-equalize]').forEach((propElem) => {
      let isClosestRoot = true
      let e = propElem

      while(e && elem != e) {
        if(e.dataset.equalizer) {
          isClosestRoot = false
        }
        e = e.parentNode
      }

      if(isClosestRoot) {
        rootElem.addProp(propElem.dataset.equalize, propElem)
      }
    })
  })
}

window.addEventListener('optimizedResize', () => Equalizer.equalize())
window.addEventListener('resized', () => Equalizer.equalize())
window.addEventListener('elementToggled', () => Equalizer.equalize())

Loader.functional((content, resolve) => {
  equalizerLoader(content)
  resolve()
})

Loader.visual((content, resolve) => {
  Equalizer.equalize(content)
  resolve()
})
