import CSRFFetch from "@/javascript/components/tools/fetch"
import Loader from "@/javascript/components/loader"

Loader.functional((content, resolve) => {
  content.querySelectorAll('.js-callback-container .callback-form-thank-you .js-reset-form').forEach((elem) => {
    if (elem.resetEnabled) {
      return
    }

    elem.resetEnabled = true
    elem.addEventListener('click', (e) => {
      e.preventDefault()
      const formContainer = e.currentTarget.closest('.js-callback-container')
      CSRFFetch(elem)
        .then((response) => {
          if (!response.ok) {
            throw Error(response.statusText)
          }
          return response.text()
        })
        .then((html) => {
          formContainer.innerHTML = html
        })
    })
  })

  resolve()
})
